import * as React from 'react';
import { Link } from 'gatsby';
import { Container, Grid, Label, Message } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';

function SeismicHazard() {
  return (
    <>
      <CenteredImage
      >
        <StaticImage
          src="./resources/dt_geo_logo.png"
          alt="DT GEO Logo."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <H1>
        Simulating Earthquake Ground-Motion with Salvus
      </H1>
      <p>
        The simulation of earthquake ground-motion is an important task in seismic hazard assessment. 
        In particular, simulations can help to fill the data gap that naturally exists due 
        the rare occurence of high-magnitude earthquakes in regions of moderate or low seismicity. 
        Large pools of simulated data can thus be used to establish so-called ground-motion 
        prediction equations (GMPEs) that enable the estimation of statistical
        properties of ground-motions (e.g., their expected mean and standard deviations) for a potential site at risk.
        This is crucial for the estimation of seismic hazard.
      </p>
      <p>
        The Salvus software suite provides the necessary tools to accurately simulate earthquake ground-motions:  
        <ul>
        <li>
        <b>Arbitrary complex inputs</b>: 3D velocity models, optionally including attenuation and anisotropy.
        </li><li>
        <b>Topography</b>: Salvus can accurately simulate the effects of topography on seismic wave propagation.
        </li><li>
        <b>Fast computations</b>: The massively parallel implementation of Salvus (on both CPUs and GPUs) enables the rapid
        simulation of ground-motions for large catalogs of potential earthquakes and input models.
        </li><li>
        <b>Earthquake source models</b>: Salvus can simulate the seismic response for simple point sources as well as kinematic fault ruptures.
        </li>
      </ul>
      </p>

      <CenteredImage>
        <StaticImage
          src="./resources/salvus_features.png"
          alt="DT GEO Logo."
          width={500}
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <H2>Intensity Measures Implemented in Salvus</H2>
      <p>
      Salvus enables the user to output accurate time histories (i.e., seismograms) at arbitrary 
      locations inside the simulation domain. In addition to that, Salvus comes with convenience 
      functions that enable the user to automatically compute common intensity measures used in seismic risk analysis, including:
       <ul>
        <li>
        <b>Peak Ground Velocity (PGV) and Peak Ground Acceleration (PGA).</b>
        </li><li>
        <b>Arias Intensity.</b>
        </li><li>
        <b>(Pseudo-)Spectral Acceleration, Velocity, and Displacment.</b>
        </li><li>
        <b>Significant Duration.</b>
        </li><li>
        <b>Macroseismic Intensity.</b>
        </li>
      </ul>
      </p>
      <H2>Case Study: Simulating The Historic Basel Earthquake</H2>
      <p>
        In 1356, a significant earthquake occured in Basel. Intense shaking destroyed all churches and castles within a 30 km radius. 
        The earthquake's moment magnitude was estimated to lie in the range of 6.0 to 7.1. 
        Here, we use Salvus to simulate the
        ground motions throughout Switzerland caused by such an earthquake and illustrate how Salvus can accurately handle
        increasingly complex input models. In the following we assume the earthquake had a magnitude of 6.6.
      </p>

      <p>
        We simulate the earthquake on a multi-segment fault located in Basel that, on average, strikes NNE-SSW and dips 75° to the East. Salvus 
        is capable of reading common earthquake fault rupture formats and converting those to the required Salvus sources. 
      </p>

      <CenteredImage
        caption={[
          "Salvus visualization of the fault used to simulate the historic Basel earthquake."
      ]}
      >
        <StaticImage
          src="./resources/basel_fault.png"
          alt="Basel fault."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <p>
        In a first iteration, we simulate the Basel earthquake using a simple velocity model that only varies with depth and has a flat topography. 
        Simulations are performed up to a frequency of 1 Hz. On a single GPU (NVIDIA RTX 4000 SFF ADA), the simulation for a domain spanning
        over all of Switzerland takes about 2 minutes to complete. We then visualize 
        the shaking intensity in terms of macroseismic intensity, derived from the peak ground velocity according to the relations 
        provided by 
        {' '}<a
            href="#references"
            target="_self"
        >
          Faenza & Michelini (2010)
        </a>{'.'} In the near-source region, the intensity reaches degree IX. 
      </p>

      <CenteredImage
        caption={[
          "3D seismic velocity model (left) and simulated macroseismic intensity for a Magnitude 6.6 earthquake on the Basel fault (right)."
      ]}
      >
        <StaticImage
          src="./resources/shakemap_no_topo.png"
          alt="Macroseismic intensity without topography"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      
      <p>
        It is straightforward to include topography in the Salvus simulations through the use of a digital elevation model. Salvus can
        automatically generate an unstructured simulation mesh that follows the topography. Repeating the previous simulation with realistic topography leads to 
        significantly different ground motion intensities, especially in the alpine regions. This demonstrates the importance of taking topography into account
        when simulating earthquake ground-motions.
      </p>

      <CenteredImage
        caption={[
          "3D seismic velocity model with added topography (left) and resulting macroseismic intensity (right)."
      ]}
      >
        <StaticImage
          src="./resources/shakemap_topo.png"
          alt="Macroseismic intensity with topography"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <p>
        Additionally, spatial variations in the seismic velocity can lead to focusing and defocusing effects of the wavefield, which 
        in turn alter the shaking intensity. Here, we adapt a realistic 3D velocity model of Switzerland provided by 
        {' '}<a
            href="#references"
            target="_self"
        >
          Diehl et al. (2021)
        </a>{'.'} to evaluate such effects. Note that the distribution of macroseismic intensity is again significantly altered.
        This can prove critical for the evaluation of seismic hazard. 
      </p>
      <CenteredImage
        caption={[
          "Realistic seismic velocity model of Switzerland (left) and resulting macroseismic intensity (right)."
      ]}
      >
        <StaticImage
          src="./resources/shakemap_topo_3d_velmod.png"
          alt="Macroseismic intensity with topography and realistic velocity model"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      
      <p>
      Time histories (seismograms) can be readily extracted from the Salvus simulation output at arbitrary locations inside the simulation domain. The following time histories were computed
      for a seismic station located close to Basel for the three simulations described above, illustrating the differences in the local site response when considering increasingly complex input models. 
      </p>
      <CenteredImage
        caption={[
          "Simulated time histories at a station close to Basel."
      ]}
      >
        <StaticImage
          src="./resources/time_histories.png"
          alt="Basel time histories"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      
      <p>
        For further analysis with third-party seismic hazard analysis software, Salvus allows users to write the simulated intensity measures to a simple spreadsheet at 
        user-specified locations inside the simulation domain. Additionally, the spreadsheets will include information on the earthquake source and the velocity model used in the simulations. 
      </p>
      <CenteredImage
        caption={[
          "Optional spreadsheet output of shaking intensity measures."
      ]}
      >
        <StaticImage
          src="./resources/flatfile.png"
          alt="Spreadsheet output"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>


      <H2>References</H2>
      <p>
      <ul>
        <li>
          <b>L. Faenza & A. Michelini (2010):</b>{' '}
          <a
            href="https://doi.org/10.1111/j.1365-246X.2009.04467.x"
            target="_blank"
          >
            Regression analysis of MCS intensity and ground motion parameters in Italy and its application in ShakeMap.

          </a>{' '}
          <i>Geophysical Journal International.</i> 180(3), pp. 1138-1152. doi:{' '}
          <a
            href="https://doi.org/10.1111/j.1365-246X.2009.04467.x"
            target="_blank"
          >
            10.1111/j.1365-246X.2009.04467.x
          </a>{' '}
        </li>
        <li>
        <b>T. Diehl et al. (2021):</b>{' '}
          <a
            href="https://doi.org/10.1029/2021JB022155"
            target="_blank"
          >
            Improving Absolute Hypocenter Accuracy With 3D Pg and Sg Body-Wave Inversion Procedures and Application to Earthquakes in the Central Alps Region.
          </a>{' '}
          <i>Journal of Geophysical Research: Solid Earth,</i> 126, e2021JB022155. doi:{' '}
          <a
            href="https://doi.org/10.1029/2021JB022155"
            target="_blank"
          >
            10.1029/2021JB022155
          </a>{' '}
        </li>
        </ul>
      </p>
      <H2>Funding</H2>
      <p>
      This work has received funding from the European Union's Horizon Europe research and 
      innovation programme under grant agreement no. <b>101058129</b> (DT-Geo). Visit the 
       
       {' '}<a
            href="https://dtgeo.eu"
            target="_blank"
        >
         DT Geo website
        </a>{' '} for more information.
      </p>
      <CenteredImage
      >
        <StaticImage
          src="./resources/dt_geo_logo.png"
          alt="DT GEO Logo."
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <Grid style={{ marginTop: '5ex' }}>
        <Grid.Row centered>
          The Salvus software suite is Mondaic's flagship code to solve problems like
          the above
        </Grid.Row>
        <Grid.Row centered>
          ... and many more!
        </Grid.Row>
        <Grid.Row centered>
          <ContactUsFormModal />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default withLayout(SeismicHazard);
